import React, { useState } from "react"
import Swiper from "react-id-swiper"
import { Navigation, Pagination } from "swiper/js/swiper.esm"
import "./careerSwiper.scss"

import c1 from "../images/c1.jpg"
import c2 from "../images/c2.jpg"
import c3 from "../images/c3.jpg"
import c4 from "../images/c4.jpg"
import c5 from "../images/c5.jpg"
import c6 from "../images/c6.jpg"
import c7 from "../images/c7.jpg"
import c8 from "../images/c8.jpg"
import c11 from "../images/c11.jpg"
import c12 from "../images/c12.jpg"
import c13 from "../images/c13.jpg"
import c14 from "../images/c14.jpg"

const WorkLifeSwiper = () => {
  const [position, setPosition] = useState(0)

  const row1 = [c1, c2, c3, c5, c6, c7]
  const row2 = [c8, c4, c11, c12, c13, c14]
  const allImages = [...row1, ...row2]

  const firstRowImages = [...row1, ...row1.slice(0, 3)]
  const secondRowImages = [...row2.slice(-3), ...row2]

  const totalSlides = row1.length - 2

  const handleNext = () => {
    setPosition(prev => (prev + 1) % totalSlides)
  }

  const handlePrev = () => {
    setPosition(prev => (prev - 1 + totalSlides) % totalSlides)
  }

  const params = {
    modules: [Pagination],
    pagination: {
      el: ".swiper-pagination",
      clickable: true,
      type: "bullets",
    },
    spaceBetween: 10,
    slidesPerView: 1,
  }

  return (
    <div className="work-life-gallery">
      {/* Desktop/Tablet View */}
      <div className="desktop-view">
        <div className="gallery-container">
          <div
            className="image-row"
            style={{
              transform: `translateX(-${position * 25}%)`,
              transition: "transform 0.5s ease-in-out",
            }}
          >
            {firstRowImages.map((img, index) => (
              <div
                key={`top-${index}`}
                className={`gallery-item size-${index % 3} col-lg-4 col-md-6`}
              >
                <img src={img} alt={`worklife-${index + 1}`} />
              </div>
            ))}
          </div>

          <div
            className="image-row"
            style={{
              transform: `translateX(${position * 23}%)`,
              transition: "transform 0.5s ease-in-out",
            }}
          >
            {secondRowImages.map((img, index) => (
              <div
                key={`bottom-${index}`}
                className={`gallery-item size-${(index + 2) %
                  3} col-lg-4 col-md-6`}
              >
                <img src={img} alt={`worklife-${index + 8}`} />
              </div>
            ))}
          </div>
        </div>

        <div className="gallery-controls">
          <button onClick={handlePrev} className="gallery-button prev">
            &#8592;
          </button>
          <button onClick={handleNext} className="gallery-button next">
            &#8594;
          </button>
        </div>
      </div>

      {/* Mobile View */}
      <div className="mobile-view">
        <Swiper {...params}>
          {allImages.map((img, index) => (
            <div key={`mobile-${index}`}>
              <div className="swiper-slide-content">
                <img
                  src={img}
                  alt={`worklife-${index + 1}`}
                  style={{
                    width: "100%",
                    height: "350px",
                    objectFit: "cover",
                  }}
                />
              </div>
            </div>
          ))}
        </Swiper>
      </div>
    </div>
  )
}

export default WorkLifeSwiper
